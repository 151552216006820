import preloadImage from "utils/preloadImage";
import addProtocol from "utils/addProtocol";

function imgToImgContext(image: CanvasImageSource, cropTopPx: number) {
    const canvas = document.createElement("canvas");
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;

    const context = canvas.getContext("2d");

    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
    context.drawImage(image, 0, -cropTopPx, canvas.width, canvas.height);

    return context;
}

function compareImageUrls(
    image1Src: string,
    image2Src: string,
    cropTopPx: number,
    incomparableReasons: { length: number; join: (arg0: string) => null },
) {
    cropTopPx = cropTopPx || 0;

    const returnObject = {
        isComparable: null,
        diffPixelRatio: null,
        dataUrl: null,
        comment: null,
    };

    if (Array.isArray(incomparableReasons) && incomparableReasons.length > 0) {
        returnObject.isComparable = false;
        returnObject.comment = incomparableReasons.join(", ");
        return new Promise((resolve) => {
            resolve(returnObject);
        });
    }

    return Promise.all([preloadImage(image1Src), preloadImage(image2Src)])
        .then(([{ imageObj: image1 }, { imageObj: image2 }]) => {
            if (
                image1.naturalWidth !== image2.naturalWidth ||
                image1.naturalHeight !== image2.naturalHeight
            ) {
                returnObject.isComparable = false;
                returnObject.comment = `Images have different size: ${image1.naturalWidth}x${image1.naturalHeight} and ${image2.naturalWidth}x${image2.naturalHeight}`;

                return returnObject;
            }

            const width = image1.naturalWidth;
            const height = image1.naturalHeight;

            const img1Context = imgToImgContext(image1, cropTopPx);
            const img2Context = imgToImgContext(image2, cropTopPx);

            const diffCanvas = document.createElement("canvas");
            diffCanvas.width = width;
            diffCanvas.height = height;
            const diffContext = diffCanvas.getContext("2d");

            const img1 = img1Context.getImageData(0, 0, width, height);
            const img2 = img2Context.getImageData(0, 0, width, height);
            const diff = diffContext.createImageData(width, height);

            return new Promise((resolve) => {
                // Image comparison is expensive, we use web workers to keep the UI responsive
                const worker = new Worker("./services/PixelMatchWorker.ts");

                worker.addEventListener(
                    "message",
                    function (event) {
                        const { numDiffPixels, diffData } = event.data;

                        diffContext.putImageData(
                            new ImageData(diffData, width, height),
                            0,
                            0 + cropTopPx,
                        );

                        returnObject.isComparable = true;
                        returnObject.diffPixelRatio = numDiffPixels / (width * height);
                        returnObject.dataUrl = diffCanvas.toDataURL();

                        resolve(returnObject);

                        worker.terminate();
                    },
                    false,
                );

                worker.addEventListener(
                    "error",
                    function (errorEvent) {
                        returnObject.isComparable = false;
                        returnObject.comment = errorEvent.message;
                    },
                    false,
                );

                worker.postMessage({
                    img1Data: img1.data,
                    img2Data: img2.data,
                    diffData: diff.data,
                    width,
                    height,
                    options: {
                        threshold: 0.015, //matching threshold (0 to 1); smaller is more sensitive
                        diffColor: [255, 105, 97],
                        diffColorAlt: [119, 221, 119],
                        aaColor: [0, 96, 199], // the color of anti-aliased pixels in the diff output
                    },
                });
            });
        })
        .catch((err) => {
            console.error(err);
            returnObject.isComparable = false;
            returnObject.comment = err.message;

            return returnObject;
        });
}

export const compareImages = (screens1, screens2, cropTopPx, incomparableReasons) => {
    return Promise.all(
        screens1
            .map((screen1, idx) => {
                const screen2 = screens2[idx];

                // only when we have both screens
                if (!screen2) return null;

                const firstScreenUrl = addProtocol(screen1.file);
                const secondScreenUrl = addProtocol(screen2.file);

                return compareImageUrls(
                    firstScreenUrl,
                    secondScreenUrl,
                    cropTopPx,
                    incomparableReasons,
                );
            })
            .filter(Boolean),
    );
};

export const calcStatusBarHeight = (platform: string, device: string) => {
    const heightForPlatform =
        {
            // Status Bar Height for iOS taken from
            // https://kapeli.com/cheat_sheets/iOS_Design.docset/Contents/Resources/Documents/index#//dash_ref/Category/Common%20Design%20Elements/1
            ios: {
                "iPad Air 2": 40,
                "iPhone SE": 40,
                "iPhone SE (3rd generation)": 44,
                "iPhone 6": 40,
                "iPhone 7": 40,
                "iPhone 7 Plus": 54,
                "iPhone 8": 40,
                "iPhone X": 132,
                "iPhone 11": 88,
                "iPhone 11 Pro": 132,
                "iPhone 14 Pro Max": 162,
                "iPad mini (5th generation)": 112,
            },
            // For Android the height of status bar is always 24 dp.
            // For multipliers see https://developer.android.com/training/multiscreen/screendensities#TaskProvideAltBmp
            android: {
                "Samsung Galaxy J5": 24 * 2,
                "Samsung Galaxy S4": 24 * 3,
                "Samsung Galaxy S7": 24 * 4,
                "Samsung Galaxy S8": 24 * 2.2,
                "Samsung Galaxy S10": 24 * 3,
            },
        }[platform] || {};

    return heightForPlatform[device] || 0;
};
